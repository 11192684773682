<template>
  <div class="main-wrap">
    <!-- main -->
    <main>
      <div class="main-section">
        <div class="container h-100 small">
          <router-link to="/" class="page-close">
            <img :src="require('@/assets/img/times.png')" alt="" />
          </router-link>
          <router-link to="/" class="logo">
            <img :src="require('@/assets/img/logo.png')" alt="" />
          </router-link>
          <div class="before-data">
            <h4>
              <button @click.prevent="exportToJson">
                <!--<img :src="require('@/assets/img/envelope.png')" alt="" />-->
                Save as JSON
              </button>
            </h4>
            <h4>
              <button @click.prevent="exportToCsv">
                <!--<img :src="require('@/assets/img/envelope.png')" alt="" />-->
                Save as CSV
              </button>
            </h4>
          </div>
          <div class="data-table">
            <div class="head-part">
              <div class="search-filter">
                <div class="search-box">
                  <div class="input-group">
                    <input
                      v-model="filter"
                      type="text"
                      placeholder="filter results"
                    />
                    <button @click.prevent="addFilter">
                      <BaseIcon icon="search" />
                    </button>
                  </div>
                </div>
                <div
                  class="current-filter"
                  v-for="(filter, idx) of filters"
                  :key="`${filter}-${idx}`"
                >
                  <button @click.prevent="removeFilter(filter)">
                    <BaseIcon icon="times" />
                  </button>
                  <div>
                    <span>
                      {{ filter }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="about-search">
                <div class="data">
                  <h4>{{ matchedCodes }}</h4>
                  <span>Matched({{ matchedPercentage }}%)</span>
                </div>
                <div class="data">
                  <h4>{{ unmatchedCodes }}</h4>
                  <span>Un-Matched({{ unmatchedPercentage }}%)</span>
                </div>
              </div>
            </div>
            <div class="table-main">
              <div class="data-table-inner">
                <table class="table">
                  <thead>
                    <tr>
                      <td class="data-isrc">ISRC</td>
                      <td class="data-iswc">ISWC</td>
                      <td class="data-performer">Performer</td>
                      <td class="data-title">Title</td>
                      <td class="data-party">Party</td>
                      <td class="data-artist_name">Known As</td>
                      <td class="data-admin">Admin By</td>
                      <td class="data-role">Role</td>
                      <td class="data-society">Society</td>
                      <td class="data-ipi">IPI</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, idx) of filteredData"
                      :key="`${item.mlc_party_id}-${idx}`"
                    >
                      <td class="data-isrc">{{ item.isrc }}</td>
                      <td class="data-iswc">{{ item.iswc }}</td>
                      <td class="data-performer">{{ item.performers }}</td>
                      <td class="data-title">{{ item.title }}</td>
                      <td class="data-party">{{ item.interested_party }}</td>
                      <td class="data-artist_name">{{ item.artist_name }}</td>
                      <td class="data-admin">{{ item.party_admin }}</td>
                      <td class="data-role">{{ item.role }}</td>
                      <td class="data-society">{{ item.society }}</td>
                      <td class="data-ipi">{{ item.ipi }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- main end -->
  </div>
</template>

<script>
import { publisherDataComputed } from "@/views/apps/services/publisherDataService";
import { downloadFile } from "@/helpers";

export default {
  data() {
    return {
      filter: "",
      filters: [],
    };
  },
  computed: {
    ...publisherDataComputed,
    matchedPercentage() {
      return ((100 * this.matchedCodes) / (this.totalCodes || 0)).toFixed(2);
    },
    unmatchedPercentage() {
      return ((100 * this.unmatchedCodes) / (this.totalCodes || 0)).toFixed(2);
    },
    filteredData() {
      if (!this.filters.length) {
        return this.publisherData;
      }
      const filters = this.filters.map((s) => s.toLowerCase());
      return this.publisherData.filter((item) => {
        const title = String(item.title).toLowerCase();
        return filters.some((filter) => title.includes(filter));
      });
    },
  },
  created() {
    if (!this.publisherData.length) this.$router.push("/");
  },
  methods: {
    removeFilter(filter) {
      this.filters = this.filters.filter((item) => item !== filter);
    },
    emailResults() {
      console.log("email results");
    },
    addFilter() {
      const filter = this.filter;
      this.filter = "";
      this.filters.push(filter);
    },
    exportToJson() {
      downloadFile({
        data: JSON.stringify(this.filteredData),
        fileName: "publisherData.json",
        fileType: "text/json",
      });
    },
    exportToCsv() {
      let headers = [
        "isrc",
        "interested_party",
        "performers",
        "apple_artist_ids",
        "title",
        "iswc",
        "mlc_party_id",
        "ascap_party_id",
        "party_admin",
        "ipi",
        "society",
        "role",
        "artist_name",
        "last_updated",
      ].join(",");

      // Convert users data to a csv
      let publisherDataCsv = this.filteredData.reduce((acc, item) => {
        // const { id, name, surname, age } = user;
        acc.push(
          Object.values(item)
            .map((item) => `"${item}"`)
            .join(",")
        );
        return acc;
      }, []);

      downloadFile({
        data: [headers, ...publisherDataCsv].join("\n"),
        fileName: "publisherData.csv",
        fileType: "text/csv",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-section {
  padding-top: 150px !important;
}

.before-data {
  display: flex;
  align-items: center;
  h4 + h4 {
    margin-left: 1rem;
  }
}

.table {
  table-layout: fixed;
}

.data-isrc {
  width: 200px;
}

.data-performer {
  width: 300px;
}

.data-title {
  width: 300px;
}

.data-party {
  width: 300px;
}

.data-artist_name {
  width: 300px;
}

.data-admin {
  width: 300px;
}

.data-role {
  width: 250px;
}

.data-society {
  width: 200px;
}

.data-ipi {
  width: 200px;
}

.data-iswc {
  width: 200px;
}
</style>